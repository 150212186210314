﻿.input {
    border: 1px solid transparent;
    color: $color-eggplant;
    border-color: $color-medium-gray;
    background: $color-white;
    height: 32px;
    padding: 6px 8px;
    outline: none;
    max-width: 100%;
    width: 100%;
    display: block;
    border-radius: 0;

    &:hover:not([disabled]) {
        box-shadow: inset 0 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    &:focus {
        border-color: $color-gambogeish-gray;
    }

    &[disabled] {
        color: $color-gray;
        border-color: $color-gray;
        background: $color-silver;
        cursor: not-allowed;
    }

    &::-webkit-input-placeholder { /* chrome, safari, edge */
      color: $color-gray;
    }
    &::-moz-placeholder { /* mozilla */
      color: $color-gray;
      opacity: 1;
    }
}

.stepper-container {
    position: relative;
    display: flex;

    .stepper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 16px;
        height: 32px;

        .stepper-button {
            background-color: $color-light-gambogeish-gray;
            border: 1px solid $color-medium-gray;
            margin: 0;
            padding: 0;
            flex: 1;
            min-height: 0;
            min-width: 0;


            &:hover {
                z-index: 1;
                background-color: $color-light-gray;
            }

            &:focus {
                z-index: 2;
            }

            &.increment {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                margin-bottom: -1px;
            }

            &.decrement {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                line-height: 1;
            }
        }
    }
}
