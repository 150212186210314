.virtual-tour-popup-modal {
    .stepTitle {
        font-weight: bold;
        margin-bottom: 8px;

        span {
            float: right;
        }
    }
    .text-with-sprite {
        display: inline-block;
        .sprite {
            vertical-align: middle;
            font-size: 32px;
            display: inline-block;
            margin-top: -9px;
        }
    }

    &.virtual-tour-large {
        width: 500px;
    }

    u {
        color: $color-hilti;
    }
}

.virtual-tour-popup-home-page {
    width: 250px !important;
}

.smart-design-menu-position {
    top: 58px !important;
}

.smart-design-virtual-tour-popup-info {
    font-size: 16px;
    font-weight: 700;
}

.virtual-tour-large-title {
    font-size: large;
    color: $color-hilti;
    margin-bottom: 8px;
}

.virtual-tour-large-subtitle {
    font-size: medium;
    color: $color-chilli;
    margin-bottom: 8px;
}

.virtual-tour-title-subtitle {
    .image-container {
        float: left;
        width: 64px;
        min-width: 64px;
        height: 64px;
        margin-right: 20px;
    }
}

.virtual-tour-text {
    margin-top: 20px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}
