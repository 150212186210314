@import '../bundles/main.scss';
@import '../bundles/anchor-family.scss';
@import '../bundles/product-selector.scss';
@import '../bundles/decking.scss';

@import 'sprites.scss';

@mixin create-sprites($sprites) {
    @each $sprite in $sprites {
        .sprite.#{nth($sprite, 10)} {
            @include sprite($sprite)
        }
    }
}

@mixin create-responsive-sprites($sprites) {
    @each $sprite in $sprites {
        .sprite-responsive.#{nth($sprite, 10)} {
            @include sprite-responsive($sprite)
        }
    }
}

.sprite {
    display: block;
}

.sprite-responsive {
    display: block;

    &::after {
        content: ' ';
        display: block;
        max-width: 100%;
        height: 0;
    }
}

/*************/
/*  SPRITES  */
/*************/

// TODO: remove sprites from create-sprites if they are not needed (if they are only responsive)

// static sprites
@include create-sprites($spritesheet-main-sprites);

// responsive sprites
@include create-responsive-sprites(($sprite-computer $sprite-anchor-family-no-photo-available));
@include create-responsive-sprites($spritesheet-product-selector-sprites);
@include create-responsive-sprites($spritesheet-decking-sprites);
