﻿@import '@profis-engineering/pe-ui-common/styles/colors.scss';
@import "../sprites.scss";

.checkbox {
    display: inline-flex;
    align-items: center;

    &.disabled .checkbox-label {
        color: $color-gray;
    }

    .checkbox-button {
        flex-shrink: 0;
    }

    .checkbox-label {
        color: $color-eggplant;
        padding-left: 8px;
        cursor: default;
    }
}

.checkbox-button {
    width: 16px;
    height: 16px;
    position: relative;
    outline: none;

    &:focus span {
        border-color: $color-grape;
    }

    input {
        position: absolute;
        pointer-events: none;
        opacity: 0;

        &:checked {
            & + span:after {
                visibility: visible;
            }

            &[disabled] + span:after {
                visibility: visible;
            }
        }

        &[disabled] + span {
            cursor: not-allowed;
            opacity: 0.5;
            background-color: $color-coal;
        }
    }

    span {
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
        position: absolute;
        border: 1px solid $color-iron;
        background: $color-white;

        &:after {
            @include sprite($sprite-checkbox);
            content: '';
            position: absolute;
            visibility: hidden;
        }
    }
}
