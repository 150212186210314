@import '../../../src/styles/fonts.scss';
@import '@profis-engineering/pe-ui-common/styles/colors.scss';

.default-modal {
    @extend .base-modal;

    .modal-content {
        .modal-body {
            font-size: 12px;
            padding: 16px;

            ul {
                padding-left: 16px;
                list-style-type: circle;
            }
        }

        .modal-body .section,
        .modal-body.section {
            &:last-child {
                margin-bottom: 0;
            }

            .control-row {
                margin-bottom: 16px;
                display: flex;
                flex-direction: column;

                &:last-child {
                    margin-bottom: 0;
                }

                .input {
                    width: 100%;
                }

                &.multiple {
                    flex-direction: row;

                    .control-column {
                        flex: 1;
                        min-width: 0;
                        margin-right: 16px;
                        display: flex;
                        flex-direction: column;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            .section-row {
                display: flex;

                .section-column {
                    flex: 1;
                    min-width: 0;
                    padding: 0 16px;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;

                        &.separator {
                            border-right: none;
                        }
                    }

                    &.separator {
                        margin-top: -16px;
                        padding-top: 16px;
                        margin-bottom: -16px;
                        padding-bottom: 16px;
                        border-right: 1px solid $color-metal;
                    }
                }

                .size2 {
                    flex: 2 !important;
                }

                .size3 {
                    flex: 3 !important;
                }
            }
        }
    }

    #surface-treatment-table td,
    #location-factor td,
    #rebar-epoxycoated td,
    #loads-spliceclasses-table td,
    #min-area-reinforcement-table td,
    #min-area-reinforcement-table th {
        border: 1px solid !important;
        font-size: 10px;
    }

    #is-anchor-theory-hna-table td,
    #is-yield-design-hna-table td {
        border: none;
        text-align: justify;
        width: 50vw;
        vertical-align: text-top;
    }

    #is-yield-design-hna th {
        background-color: #A5A6A8 !important;
        border: none;
    }

    #is-yield-design-hna th:nth-child(1) {
        text-align: left !important;
        color: white;
    }

    #is-yield-design-hna th:nth-child(2) {
        text-align: right !important;
        color: white;
    }
}

.base-modal {
    .modal-content {
        border-radius: 0;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);

        .modal-header {
            height: 48px;
            display: flex;
            align-items: center;
            background: $color-white;
            padding: 0 16px;
            border-bottom: 1px solid $color-medium-gray;
            z-index: 1;
            position: relative;
            flex-direction: row;


            .modal-title {
                //flex-grow: 1;
                flex: 1 1 auto;
                margin-right: 16px;
                font-size: 12px;
                color: $color-iron;
                font-weight: 700;
                text-transform: uppercase;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                .low {
                    font-size: 14px;
                    text-transform: lowercase;
                }
            }

            .close {
                padding: 0;
                margin: -8px 0 0 0;
                font-size: 26px;
                color: $color-iron;
                text-shadow: none;
                opacity: 1;
                font-family: $font-hilti-wide;

                &:not([disabled]):hover {
                    opacity: 1;
                    color: $color-coal;
                }

                &[disabled] {
                    cursor: not-allowed;
                }
            }
        }

        .modal-footer {
            height: 48px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: left;
            background: $color-white;
            border-top: 1px solid $color-medium-gray;
            z-index: 1;
            position: relative;

            .modal-button {
                &:not(:first-child) {
                    margin-left: 8px;
                }
            }
        }
    }
}

.modal-open .modal {
    overflow: auto;
    text-align: center;

    .modal-dialog {
        margin: 30px 0;
        display: inline-block;
        text-align: left;
        width: 480px;
    }

    .modal-adaptive {
        width: auto;

        @media (max-width: 1279px) {
            max-width: calc(100vw - 60px);
        }

        @media (min-width: 1280px) {
            max-width: 1280px;
        }
    }

    .modal-fullWidth {
        max-width: none;
        width: 100%;
        margin-top: 47px;

        .modal-content {
            border-left-width: 0;
            border-right-width: 0;
        }
    }

    .modal-xl {
        width: 1280px;
        max-width: calc(100vw - 60px);
        min-width: 880px;
    }

    .modal-lg {
        width: 800px;
    }

    .modal-sm {
        width: 300px;
    }

    .modal-auto {
        width: auto;
    }
}

.modal-open .modal-new {
    overflow: auto;
    text-align: center;

    .modal-dialog {
        margin: 30px 0;
        display: inline-block;
        text-align: left;
        width: 480px;
        position: absolute;
        transform: translate(-50%, 50%);
    }

    .modal-adaptive {
        width: auto;

        @media (max-width: 1279px) {
            max-width: calc(100vw - 60px);
        }

        @media (min-width: 1280px) {
            max-width: 1280px;
        }
    }

    .modal-fullWidth {
        max-width: none;
        width: 100%;
        margin-top: 47px;

        .modal-content {
            border-left-width: 0;
            border-right-width: 0;
        }
    }

    .modal-xl {
        width: 1280px;
        max-width: calc(100vw - 60px);
        min-width: 880px;
    }

    .modal-lg {
        width: 800px;
    }

    .modal-sm {
        width: 300px;
    }

    .modal-auto {
        width: auto;
    }
}

.modal-open .modal-checkbot {
    overflow: auto;
    text-align: center;

    .modal-dialog {
        margin: 30px auto;
        text-align: left;
        width: 480px;
        display: flex;
        align-items: center;
        min-height: calc(100% - var(--bs-modal-margin) * 2 - 30px);
    }

    .modal-adaptive {
        width: auto;

        @media (max-width: 1000px) {
            max-width: 480px;
        }

        @media (min-width:1001px) and (max-width: 1399px) {
            max-width: 920px;
        }

        @media (min-width: 1400px) {
            max-width: 1366px;
        }
    }
}