﻿// converted with http://onlinefontconverter.com/

@font-face {
    font-family: hilti;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/HiltiSmall-Roman.eot');
    src: url('../fonts/HiltiSmall-Roman.eot?#iefix') format('embedded-opentype'),
         url('../fonts/HiltiSmall-Roman.woff2') format('woff2'),
         url('../fonts/HiltiSmall-Roman.woff') format('woff'),
         url('../fonts/HiltiSmall-Roman.ttf') format('truetype'),
         url('../fonts/HiltiSmall-Roman.svg#HiltiSmall-Roman') format('svg');
}

@font-face {
    font-family: hilti;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/HiltiSmall-Bold.eot');
    src: url('../fonts/HiltiSmall-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/HiltiSmall-Bold.woff2') format('woff2'),
         url('../fonts/HiltiSmall-Bold.woff') format('woff'),
         url('../fonts/HiltiSmall-Bold.ttf') format('truetype'),
         url('../fonts/HiltiSmall-Bold.svg#HiltiSmall-Bold') format('svg');
}

@font-face {
    font-family: hilti-wide;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/HiltiSmall-Extended.eot');
    src: url('../fonts/HiltiSmall-Extended.eot?#iefix') format('embedded-opentype'),
         url('../fonts/HiltiSmall-Extended.woff2') format('woff2'),
         url('../fonts/HiltiSmall-Extended.woff') format('woff'),
         url('../fonts/HiltiSmall-Extended.ttf') format('truetype'),
         url('../fonts/HiltiSmall-Extended.svg#HiltiSmall-Extended') format('svg');
}

@font-face {
    font-family: hilti-wide;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/HiltiSmall-HeavyExtended.eot');
    src: url('../fonts/HiltiSmall-HeavyExtended.eot?#iefix') format('embedded-opentype'),
         url('../fonts/HiltiSmall-HeavyExtended.woff2') format('woff2'),
         url('../fonts/HiltiSmall-HeavyExtended.woff') format('woff'),
         url('../fonts/HiltiSmall-HeavyExtended.ttf') format('truetype'),
         url('../fonts/HiltiSmall-HeavyExtended.svg#HiltiSmall-HeavyExtended') format('svg');
}
