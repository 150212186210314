﻿@import '@profis-engineering/pe-ui-common/styles/colors.scss';

.text-label {
    color: $color-eggplant;
}

.control-label {
    color: $color-0x887f6e;
    padding-bottom: 4px;
    text-overflow: ellipsis;
    margin-bottom: 0px;
}
