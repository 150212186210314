﻿@import '@profis-engineering/pe-ui-common/styles/colors.scss';

.notification-container {
    line-height: 1.33;
    font-size: 12px;
    font-weight: 400;
    padding: 8px 8px 8px 0;
    display: flex;
    margin-bottom: 16px;
    overflow: hidden;

    &.notification-info {
        background: $color-light-gray;
        color: $color-eggplant;
        border: 1px solid $color-medium-gray;

        &.white-background {
            background-color: $color-white;
        }
    }

    &.notification-alert {
        background: $color-yellow;
        color: $color-eggplant;
        border: 1px solid $color-medium-gray;
    }

    &.notification-message {
        background: $color-white;
        color: $color-eggplant;
        border: 1px solid $color-medium-gray;
    }

    .notification-content {
        margin: 0;
        width: 100%;

        &.notification-content-text {
            white-space: pre-wrap;
            word-break: break-word;

            span.additional-info {
                cursor: default;
            }

            span.additional-info:hover {
                font-weight: bold;
            }
        }

        &.offline-info {
            margin-right: 8px;

            .text {
                margin-bottom: 8px;
            }

            .button {
                margin-bottom: 8px;
                width: 100%;
            }
        }

        sub {
            font-size: 80%
        }
    }

    &.notification-marketing {
        flex-direction: column;
        padding: 8px;

        img {
            width: 100%;
        }

        a {
            width: 100%;
            margin-top: 8px;

            -webkit-appearance: button;
            -moz-appearance: button;
            appearance: button;

            text-decoration: none;
            color: $color-hilti;
        }
    }

    .notification-icon {
        display: inline-block;
        vertical-align: bottom;
        margin-top: -6px;
        margin-bottom: -6px;
    }
}

.notification-indicator-wrapper {
    display: flex;
    flex: 0 1 auto;
    margin-left: 14px;

    &-hidden {
        visibility: hidden;
    }

    .notification-indicator-container {
        display: flex;
        margin: 0 2px;
        line-height: 1;
        font-size: 10px;
        font-weight: 400;
        padding: 2px 2px;
        text-align:center;

        .notification-indicator-content {
            vertical-align: middle;
            display: flex;
            align-items:center;
            padding: 0 2px 0 2px;
        }

        &.notification-info {
            background: $color-light-gray;
            color: $color-eggplant;
            border: 1px solid $color-medium-gray;
        }

        &.notification-alert {
            background: $color-yellow;
            color: $color-eggplant;
            border: 1px solid $color-medium-gray;
        }

        &.notification-message {
            background: $color-white;
            color: $color-eggplant;
            border: 1px solid $color-medium-gray;
        }

        .sprite {
            margin: -8px;
        }
    }
}
