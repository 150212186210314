﻿@import '@profis-engineering/pe-ui-common/styles/colors.scss';

.button {
    font-weight: 700;
    border-color: $color-chilli;
    min-width: 96px;
    min-height: 32px;
    padding: 0 8px;
    border: 1px solid;
    margin: 0;
    outline: none;
    display: block;

    &[disabled] {
        cursor: not-allowed;
    }
}

// default
.button-default {
    background: $color-light-gambogeish-gray;
    border-color: $color-medium-gray;
    border-width: 1px;
    color: $color-iron;

    &:hover:not([disabled]):not(:active) {
        background: $color-amberish-gray;
    }

    &:focus:not(:active) {
        border-color: $color-gambogeish-gray;
    }

    &:active:not([disabled]) {
        background: $color-amberish-gray;
        border-color: $color-gambogeish-gray;
    }

    &[disabled] {
        background: $color-gray;
        opacity: 0.5;
    }

    &.active {
        background: $color-amberish-gray;
        border-color: $color-gambogeish-gray;
    }

    &.active[disabled] {
        opacity: 0.75;
    }
}

.btn-check + .button-default.btn:hover {
    border-color: $color-medium-gray;
}

.btn-check:checked + .button-default {
    background: $color-amberish-gray;
    border-color: $color-gambogeish-gray;
}

.btn-check:checked + .button-default[disabled] {
    opacity: 0.75;
}

// primary
.button-primary {
    background: $color-iron;
    border-color: $color-eggplant;
    color: $color-white;

    &:hover:not([disabled]):not(:active) {
        background: $color-coal;
        border-color: $color-licorice;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    }

    &:focus:not(:active) {
        border-color: $color-coal;
    }

    &:active:not([disabled]) {
        background: $color-coal;
    }

    &.active {
        background: $color-coal;
    }

    &.active[disabled] {
        opacity: 0.75;
    }
}

.btn-check + .button-primary.btn:hover {
    border-color: $color-eggplant;
}

.btn-check:checked + .button-primary {
    background: $color-coal;
}

.btn-check:checked + .button-primary[disabled] {
    opacity: 0.75;
}

// CTA
.button-CTA {
    background: $color-hilti;
    color: $color-white;

    &:hover:not([disabled]):not(:active) {
        background: $color-chilli;
    }

    &:focus:not(:active) {
        background: $color-chilli;
    }

    &:active:not([disabled]) {
        background: $color-chilli;
    }

    &[disabled] {
        background: $color-gray;
        opacity: 0.5;
    }

    &.active[disabled] {
        opacity: 0.75;
    }
}

.btn-check:checked + .button-CTA[disabled] {
    opacity: 0.75;
}

// ghost
.button-ghost {
    background: $color-white;
    border-color: $color-medium-gray;
    border-width: 1px;
    color: $color-iron;

    &.button-ghost-white {
        background: $color-white;
    }

    &:hover:not([disabled]):not(:active) {
        border-color: $color-medium-gray;
        background: $color-light-gray;
    }

    &:focus:not(:active) {
        border-color: $color-gambogeish-gray;
    }

    &:active:not([disabled]) {
        border-color: $color-gambogeish-gray;
    }

    &[disabled] {
        background: $color-gray;
        opacity: 0.5;
    }

    &.active {
        background: $color-light-yellowish-gray;
        border-color: $color-gambogeish-gray;
    }

    &.active[disabled] {
        opacity: 0.75;
    }
}

.btn-check + .button-ghost.btn:hover {
    border-color: $color-medium-gray;
}

.btn-check:checked + .button-ghost {
    background: $color-light-yellowish-gray;
    border-color: $color-gambogeish-gray;
}

.btn-check:checked + .button-ghost[disabled] {
    opacity: 0.75;
}

// link
.button-link {
    background: transparent;
    border-color: transparent;
    color: $color-iron;

    &:hover:not([disabled]):not(:active) {
        background: $color-silver;
    }

    &:focus:not(:active) {
        border-color: $color-silver;
    }

    &:active:not([disabled]) {
        background: $color-silver;
        border-color: $color-metal;
    }

    &[disabled] {
        background: $color-gray;
        opacity: 0.5;
    }

    &.active {
        background: $color-cappuccino;
    }

    &.active[disabled] {
        opacity: 0.75;
    }
}

.btn-check + .button-link.btn:hover {
    border-color: transparent;
}

.btn-check:checked + .button-link {
    background: $color-cappuccino;
}

.btn-check:checked + .button-link[disabled] {
    opacity: 0.75;
}

// larger gray
.button-larger-gray {
    height: 50px;
    border: 1px solid $color-coal;
    background: $color-coal;
    color: $color-white;
    font-weight: 700;
    font-size: 14px;
}

// rich
.button-rich {
    min-width: 200px;
    border: 1px solid $color-medium-gray;
    background: $color-white;
    outline: none;
    padding: 0;
    margin: 0;
    display: block;

    &:hover:not([disabled]) {
        background: $color-light-gray;
    }

    &:focus {
        border-color: $color-gambogeish-gray;
    }

    &[disabled] {
        background: $color-silver;

        .button-rich-content .button-rich-text {
            color: $color-metal;
        }
    }

    .button-rich-content {
        min-height: 48px;
        display: flex;
        align-items: center;

        .button-rich-seperator {
            width: 0px;
            height: 48px;
            border-left: 1px solid  $color-medium-gray;
            margin-left: -1px;
        }

        .button-rich-image {
            padding: 8px;
            min-width: 0;
            flex: 0 0 40%;
            margin: 0;
            align-items: center;
            mix-blend-mode: multiply;
        }

        .button-rich-text {
            border-left: 1px solid $color-medium-gray;
            margin: 0 0 0 -1px;
            padding: 8px 0 8px 8px;
            min-width: 0;
            flex: 1;
            display: flex;
            align-items: center;
            text-align: left;
        }

        .button-rich-caret {
            flex: 0 0 24px;
        }
    }
}

// icon
.button-icon {
    padding: 0;
    min-width: 0;

    &:disabled {
        span.sprite {
            opacity: 0.25;
        }
    }
}

.button-grp {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    .button {
        position: relative;
        float: left;

        &:first-child {
            margin-left: 0;

            &:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }

        &:hover, &:focus, &:active {
            z-index: 2;
        }
    }

    .button + .button {
        margin-left: -1px;
    }
}

// hilti-styled
.hilti-styled-button {
    min-height: 45px;
    min-width: 96px;
    padding: 4px 10px;
    border-radius: 0;
    white-space: normal;
    margin: 0;
    outline: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.75;
    }

    .hilti-styled-image-container {
        .hilti-styled-image {
            margin: 0 auto;
        }
    }

    .hilti-styled-text {
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

// hilti-styled primary
.hilti-styled-button-primary {
    background: $color-hilti;
    border: 0;
    color: $color-white;
    transition: background-color ease 0.3s;

    &:hover:not([disabled]) {
        background: $color-0x671a3d;
    }

    &:active:not([disabled]) {
        transform: scale(0.97);
    }
}

// hilti-styled secondary
.hilti-styled-button-secondary {
    background: none;
    border: 3px solid $color-hilti;
    color: $color-hilti;
    transition: border-color ease 0.3s;

    &:hover:not([disabled]) {
        border-color: $color-0x671a3d;
        color: $color-0x671a3d;
    }

    &:active:not([disabled]) {
        transform: scale(0.97);
    }
}

// hilti-styled gray
.hilti-styled-button-gray {
    background: $color-coal;
    border: 0;
    color: $color-white;
    transition: background-color ease 0.3s;

    &:hover:not([disabled]) {
        background: $color-iron;
    }

    &:active:not([disabled]) {
        transform: scale(0.97);
    }
}
