﻿$font-size-main: 12px;
@import '@profis-engineering/pe-ui-common/styles/colors.scss';

.last-tooltip-offset {
    left: 297px !important;
}

*:focus {
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

#tooltip-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    pointer-events: none;
}

.tooltip-3d-up {
    position: absolute;
    width: auto;
    z-index: 1;
    pointer-events: none;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    line-break: auto;
    padding: 4px 8px;
    color: $color-metal;
    background: $color-almost-black;
    max-width: 200px;
    min-width: 0px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    filter: alpha(opacity=90);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.9);
    opacity: .90;

    .text {
        min-width: 0px;
        text-align: left;
        border-radius: 4px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .tooltip-arrow {
        position: relative;
        background: $color-almost-black;
        bottom: 100%;
        filter: alpha(opacity=90);
        filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.9);
        opacity: .90;

        &:after {
            position: absolute;
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            pointer-events: none;
            border-bottom-color: $color-almost-black;
            border-width: 10px;
            margin-left: -10px;
        }
    }
}

.tooltip-3d-down {
    position: absolute;
    width: auto;
    z-index: 1;
    pointer-events: none;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    line-break: auto;
    padding: 4px 8px;
    color: $color-metal;
    background: $color-almost-black;
    max-width: 200px;
    min-width: 0px;
    filter: alpha(opacity=90);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.9);
    opacity: .90;

    .text {
        min-width: 0px;
        text-align: left;
        border-radius: 4px;
    }

    .tooltip-arrow {
        position: relative;
        background: $color-almost-black;
        bottom: 100%;
        filter: alpha(opacity=90);
        filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.9);
        opacity: .90;

        &:after {
            position: absolute;
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            pointer-events: none;
            border-bottom-color: $color-almost-black;
            border-width: 10px;
            margin-left: -10px;
        }
    }
}

table {
    caption {
        display: none;
    }
}

.tauFont {
    font-family: 'Times New Roman', math, sans-serif;
    font-size: 18px !important;
}

.tauFontSmall {
    font-family: 'Times New Roman', math, sans-serif;
    font-size: 12px !important;
}

.gl-model-text-editor.stepper-container {
    position: absolute;
    z-index: 1000;

    .input {
        width: 125px;
        text-align: center;
        padding-right: 24px;
    }
}

// babylonjs/inspector styling
#inspector-host, #scene-explorer-host {
    z-index: 1000;
}
