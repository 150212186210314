﻿@use './global/buttons.scss';
@import 'fonts.scss';
@import '@profis-engineering/pe-ui-common/styles/colors.scss';
@import 'main.scss';

$tooltip-width: 352px;

.introjs-helperLayer {
    border: none;
    box-shadow: none;
}

.no-background {
    background: transparent;
}

.introjs-tooltip {
    border-radius: 0;
    min-width: 0;
    max-width: none;
    width: $tooltip-width;
    font-size: 12px;
    color: #261d24;
    padding: 0;

    .introjs-tooltiptext {
        padding: 16px;
    }

    .introjs-tooltipbuttons {
        display: flex;
        padding: 0 16px;
        background: $color-silver;
        height: 48px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
        align-items: center;
        justify-content: flex-end;

        &.prev-disabled .introjs-nextbutton {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }

        &.next-disabled .introjs-prevbutton {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }

        .introjs-button {
            @extend .button;
            margin: 0;
            text-shadow: none;
            font-family: $font-hilti;
            font-size: $font-size-main;
            text-align: center;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 32px;
            text-decoration: none;

            &:not(:first-child) {
                margin-left: 8px;
            }

            &:hover {
                text-decoration: none;
            }
        }

        .introjs-prevbutton, .introjs-nextbutton {
            @extend .button-primary;
            @extend .button-icon;
            padding-left: 4px;
            padding-right: 4px;
        }

        .introjs-button-hilti-styled {
            @extend .hilti-styled-button-primary;
            &:hover:not([disabled]):not(:active) {
                background: $color-chilli !important;
            }
            border-radius: 0px !important;
        }

        .introjs-skipbutton {
            @extend .button-default;
        }

        .introjs-disabled,
        .introjs-button.introjs-disabled {
            display: none;
        }

        .separator {
            flex: 1;
        }
    }
}

.introjs-overlay {
    background: black;
    opacity: 0.7 !important;
}

.introjs-helperLayer, .introjs-tooltipReferenceLayer, .introjs-tooltip, .introjs-overlay, .introjs-hint-pulse {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}
