﻿@import 'fonts.scss';
@import '@profis-engineering/pe-ui-common/styles/colors.scss';
@import '@profis-engineering/pe-ui-common/styles/dimensions.scss';

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

[ng-if].ng-leave, [data-ng-if].ng-leave {
    display: none;
}

[hidden] {
	display: none !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    user-select: none;           /* non-prefixed version, currently not supported by any browser */
}

.nowrap {
    white-space: nowrap;
}

html {
    height: 100%;
    width: 100%;
    display: flex;
}

body {
    display: flex;
    color: $color-licorice;
    font-family: $font-hilti;
    font-size: 12px;
    background: white;
    margin: 0;
    padding: 0;
    flex: 1 0 1260px; // sync with loads-rows min-width

    > .view {
        display: flex;
        flex: 1;
    }
}

// no scrollbar above 1260px
@media (min-width: 1260px) {
    body {
        overflow-x: hidden;
    }
}

.title-strike {
    margin-bottom: 16px;
    font-weight: 700;
    color: $color-iron;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        top: 50%;
        margin-left: 8px;
        border-bottom: $separator-line-height solid $color-metal;
    }
}

.add-on {
    background-color: $color-0x3fb550;
    border-radius: 4px;
    padding: 0 4px;
    color: $color-white;
    white-space: nowrap;
}
